import './bootstrap';

let lastScrollTop = 0;
let maxWindow = window.matchMedia("(min-width: 991px)");
const headerHeight = document.querySelector('.header-top').offsetHeight;
window.addEventListener("scroll", function(){
	let st = window.pageYOffset || document.documentElement.scrollTop;
	if (maxWindow.matches) {
		if (st <= headerHeight){
			document.getElementById('navbar').classList.remove('fixed-top');
			document.getElementById('navbar').removeAttribute('style');
		} else {
			document.getElementById('navbar').classList.add('fixed-top');
			document.getElementById('navbar').style.backgroundColor = "#fff";
		}
	}
	
	const body = document.body, html = document.documentElement;
	const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
	const halfHeight = height/3;
	if (halfHeight < lastScrollTop){
		document.getElementById('back-to-top').removeAttribute('hidden');
	} else {
		document.getElementById('back-to-top').setAttribute('hidden', true);
	}

	lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);